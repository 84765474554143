html,
body{
font-family: Avenir Next LT Pro;
  }

  .container{
    margin: 80px auto 0 auto;
    max-width: 1200px;
  }

  .nav-button-container{
    margin: 0 0 0 70%;
  }
  .searchPortal{
    font-family:'Avenir Next LT Pro';
    margin: 80px auto 0 auto;
    max-width: 1200px;
  }
  .piechart-container{
    font-family: 'Avenir Next LT Pro' ;  
    margin-left: 80%;
    display:flex;
    flex-direction:row;
    align-items:center;
    font-size: 14px;
  }
  .myTable{
    border: 20px solid #328C6F;
    font-family: 'Avenir Next LT Pro';
    font-size: 16px;
    padding: 20px;
    margin: 20px auto;
    position: absolute;
    top: 120px;
    left: 40px;
    right: 20px;
}
 
  .myDiseasesTable{
    border: 15px solid #328C6F;
    font-family: 'Avenir Next LT Pro';
    padding: 20px;
    margin: 20px auto;
    font-size: 14px;
    position: absolute;
    top: 120px;
    left: 40px;
    right: 20px;
  }
  #piechart-container{
    font-family: 'Avenir Next LT Pro' ; 
    height: 30%;
    max-width: 20%;
    margin-left: 30%;
    display:flex;
    flex-direction:row;
    align-items:center;
    


  }
  .footer-dark {
    padding:50px 0;
    color:#f0f9ff;
    background-color:#328C6F;
    }
    
    .footer-dark h3 {
    font-family:'Avenir Next LT Pro'; 
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:20px;
    }
    
    .DiseasesListButtons h2 {
    font-family:'Avenir Next LT Pro'; 
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:14px;
    }
    .footer-dark ul {
    font-family:'Avenir Next LT Pro'; 
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:16px;
    margin-bottom:0;
       
    }
    
    .footer-dark ul a {
    color:#E2E9EA;
    text-decoration:none;
    opacity:0.6;
  font-family:'Avenir Next LT Pro'; 
    }
    
    .footer-dark ul a:hover {
    opacity:0.8;
    font-family:'Avenir Next LT Pro'; 
    }
    
    @media (max-width:767px) {
    .footer-dark .item:not(.social) {
      text-align:center;
      padding-bottom:20px;
    }
    }
    
    .footer-dark .item.text {
    margin-bottom:36px;
    }
    
    .footer-dark a:link {
    font-family:'Avenir Next LT Pro'; 
    opacity:2.5;
    color: #E2E9EA;
   font-size: 16px;
    margin-bottom:0;
    

    }
    @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom:0;
    }
    }
    
    .footer-dark .item.text p {
    font-family:'Avenir Next LT Pro'; 
    
    margin-bottom:0;
    font-size: 16px;
    
    }
    
    .footer-dark .item.social {
    text-align:right;
    font-size: 14px;

    }
    
    @media (max-width:991px) {
    .footer-dark .item.social {
      text-align:center;
      margin-top:20px;
    }
    }
    
    .footer-dark .item.social > a {
    font-family:'Avenir Next LT Pro';
    font-size:20px;
    width:30px;
    height:36px;
    line-height:36px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#E2E9EA;
    opacity:0.75;
    }
   
    
    .footer-dark .item.social > a:hover {
    opacity:0.9;

    }
    
    .footer-dark .copyright {
    font-family:'Avenir Next LT Pro';  
    text-align:center;
    padding-top:24px;
    opacity:0.6;
    font-size:14px;
    margin-bottom:0;
    }